<template>
<div class="login">
  <ul class="nav">
    <li v-for="(item,index) in nav_list" :key="index" @click="clickTab(index)" :class="{active: index == nav_index}">
      {{item.text}}
    </li>
  </ul>
  <div class="content">
    <template v-if="nav_index== 0">
      <van-form @submit="submitLogin">
        <div class="item" v-for="(item,index) in login_form_list" :key="index">
          <img :src="item.icon" class="icon" alt="">
          <div class="inputBox">
            <van-field
                v-model="item.value"
                name=""
                label=""
                :placeholder="item.placeholder"
                :type="item.type"
                :rules="[{ required: true, message: item.placeholder }]"
            />
          </div>
        </div>
        <div class="pwTips">
          <div class="left">
            <van-checkbox v-model="isRemember" shape="square" icon-size="16" col>记住密码</van-checkbox>
          </div>
          <div class="right" @click="$router.push('/forgetPassword')">忘记密码</div>
        </div>
        <div style="margin: 30px 0;">
          <van-button round block type="info" native-type="submit" color="linear-gradient(180deg,#9eb0ff,#4969ff)">提交</van-button>
        </div>
      </van-form>
    </template>
    <template v-else>
      <van-form @submit="submitSingIn">
        <div class="item" v-for="(item,index) in singIn_form_list" :key="index">
          <img :src="item.icon" class="icon" alt="">
          <div class="inputBox">
            <van-field
                v-model="item.value"
                name=""
                label=""
                :placeholder="item.placeholder"
                :type="item.type"
                :rules="[{ required: true, message: item.placeholder }]"
            />
          </div>
        </div>
        <div style="margin: 30px 0;">
          <van-button round block type="info" native-type="submit" color="linear-gradient(180deg,#9eb0ff,#4969ff)">提交</van-button>
        </div>
      </van-form>
    </template>
  </div>


</div>
</template>

<script>
import { Toast } from 'vant';

export default {
  name: "login",
  components: {},
  data() {
    return {
      nav_index: 0,
      nav_list: [
          {
            type: 1,
            text: '登录'
          },
        {
          type: 2,
          text: '注册'
        },
      ],
      login_form_list: [
        {
          icon: require('/static/img/login_phone.png'),
          type: 'text',
          key: 'account',
          placeholder: '请输入您的手机号',
          value: ''
        },
        {
          icon: require('/static/img/login_pw.png'),
          type: 'password',
          key: 'password',
          placeholder: '请输入密码',
          value: ''
        }
      ],
      singIn_form_list: [
        {
          icon: require('/static/img/login_name.png'),
          type: 'text',
          key: 'username',
          placeholder: '请输入用户名',
          value: ''
        },
        {
          icon: require('/static/img/login_phone.png'),
          type: 'number',
          key: 'mobile',
          placeholder: '请输入您的手机号',
          value: ''
        },
        {
          icon: require('/static/img/login_pw.png'),
          type: 'password',
          key: 'login_pwd',
          placeholder: '请输入密码',
          value: ''
        },
        {
          icon: require('/static/img/login_pw.png'),
          type: 'password',
          key: 'login_pwd2',
          placeholder: '请确认密码',
          value: ''
        },
        {
          icon: require('/static/img/login_code.png'),
          type: 'text',
          key: 'pid',
          placeholder: '请输入邀请码',
          value: ''
        },
        {
          icon: require('/static/img/login_pw2.png'),
          key: 'password1',
          type: 'password',
          placeholder: '请输入提现密码',
          value: ''
        },
        {
          icon: require('/static/img/login_pw2.png'),
          key: 'password2',
          type: 'password',
          placeholder: '请确认提现密码',
          value: ''
        },
      ],

      //是否记住密码
      isRemember: true,
    }
  },
  mounted() {
    this.$store.commit('setFootIndex', 0)
  },
  methods: {
    clickTab(index){
      this.nav_index = index
    },
    submitLogin(){
      let form = {}
      this.login_form_list.forEach((item) => {
        form[item.key] = item.value
      })
      // eslint-disable-next-line no-undef
      $api.login(form).then((res)=>{
        Toast('登录成功')
        this.$store.commit('setToken', res.data.token)
        this.$router.replace('/index')
      }).catch(()=> {

      })
    },
    submitSingIn(){
      let { singIn_form_list }  = this;
      let form = {}
      singIn_form_list.forEach((item) => {
        form[item.key] = item.value
      })
      console.log(form)
      if(form.login_pwd !== form.login_pwd2) {
        Toast('登录密码两次输入不一致');
        return;
      }
      if(form.password1 !== form.password2) {
        Toast('提现密码两次输入不一致');
      }
      // eslint-disable-next-line no-undef
      $api.register(form).then((res)=>{
          Toast('注册成功')
          this.nav_index = 0;
          this.singIn_form_list.forEach(item => {
            item.value = ''
          })
      }).catch(()=> {

      })
    }
  }
}
</script>

<style lang="scss">
.login {
  padding: 33px;
  .nav {
    display: flex;
    align-items: center;
    li {
      font-size: 18px;
      color: #666;
      margin-right: 30px;
      &.active {
        font-size: 20px;
        font-weight: bold;
        color: #000;
      }
    }
  }
  .content {
    margin-top: 60px;
    .pwTips {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      font-size: 13px;
      color: #999;
      .van-checkbox__label {
        color: #999;
      }
    }
    .item {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ebebeb;
      height: 60px;
      .icon {
        width: 23px;
        height: 24px;
        margin-right: 5px;
        flex-shrink: 0;
      }
      .inputBox {
        flex: 1;
      }
    }

  }
}
</style>